body {
  font-family: raleway-semi-bold;
}

h1 {
  font-family: raleway-bold;
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4,
h5,
h6 {
  font-size: 18px;
}

.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: raleway-semi-bold;
}

.title.v1,
h1.v1,
h2.v1,
h3.v1,
h4.v1,
h5.v1,
h6.v1 {
  font-family: raleway-bold;
  font-size: 30px;
}

.title.v2,
h1.v2,
h2.v2,
h3.v2,
h4.v2,
h5.v2,
h6.v2 {
  font-size: 22px;
}

.title.v3,
h1.v3,
h2.v3,
h3.v3,
h4.v3,
h5.v3,
h6.v3 {
  font-size: 20px;
}

.title.v4, .title.v5, .title.v6,
h1.v4,
h1.v5,
h1.v6,
h2.v4,
h2.v5,
h2.v6,
h3.v4,
h3.v5,
h3.v6,
h4.v4,
h4.v5,
h4.v6,
h5.v4,
h5.v5,
h5.v6,
h6.v4,
h6.v5,
h6.v6 {
  font-size: 18px;
}

.paragraph {
  font-family: raleway-regular;
  color: #f8f9fa;
}

.paragraph.v1 {
  font-size: 18px;
}

.paragraph.v2 {
  font-size: 16px;
}

.paragraph.v3 {
  font-size: 14px;
}

b,
strong,
.bold {
  font-family: raleway-bold;
}

.figcaption {
  color: #f8f9fa;
  font-size: 70%;
  font-family: raleway-light;
}

.btn-science {
  font-family: raleway-regular;
  color: #f8f9fa;
  background-color: rebeccapurple;
  border-color: rebeccapurple;
}

.btn-science.active, .btn-science:active, .btn-science:focus {
  outline: none;
}

.btn-link-science {
  font-family: raleway-regular;
  color: rebeccapurple;
  text-decoration: none;
}

.btn-link-science.white-link {
  color: #f8f9fa;
}

#changeLang {
  margin: auto 14px auto 5px;
  display: inline-block;
  color: #f8f9fa;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  font-size: 0.8rem;
  width: 91px;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .title.v1 {
    font-size: 40px;
  }
  .title.v2 {
    font-size: 25px;
  }
  .title.v3 {
    font-size: 22px;
  }
  .title.v4 {
    font-size: 20px;
  }
  .title.v5 {
    font-size: 18px;
  }
  .title.v6 {
    font-size: 16px;
  }
}

@font-face {
  font-family: raleway-light;
  src: url("../Raleway-Light.71cc04d4.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-light-italic;
  src: url("../Raleway-LightItalic.1763b8c3.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-regular;
  src: url("../Raleway-Regular.108f88aa.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-italic;
  src: url("../Raleway-Italic.2efee543.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-bold;
  src: url("../Raleway-Bold.034c88fc.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-semi-bold;
  src: url("../Raleway-SemiBold.7756826a.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway-semi-bold-italic;
  src: url("../Raleway-SemiBoldItalic.a6a82318.ttf");
  font-display: swap;
}

.bg-rebecca {
  background-color: rebeccapurple;
}

.bg-science {
  background-color: #791acd;
}

.bg-blue {
  background-color: #6c91c2;
}

.bg-white {
  background-color: #f8f9fa;
}

.bg-black {
  background-color: #343a40;
}

.navbar .container-fluid .logo img {
  width: 30px;
  height: 30px;
}

.navbar .container-fluid #navbarMenu {
  flex: 0 0 100%;
}

.page-container {
  background-color: black;
  background-image: url("../fondo1.80287f08.webp");
  background-position: center top;
  background-attachment: fixed;
}

.sdbarCnt {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

.sidebar {
  background-color: #fff8e7;
}

@media (min-width: 768px) {
  .sidebar {
    order: 0;
    position: sticky;
    z-index: 1000;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .sdbarCnt {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .w-nsm-100 {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.tbar {
  position: fixed;
  top: 20%;
  width: 3pc;
  z-index: 100020;
  background: none;
  animation-name: slideInRight;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
}

.tbar-right {
  right: 0;
  float: right;
  left: auto;
}

.cardBorder {
  border-width: 3px;
  border-radius: 10px;
}

footer {
  border-top: 10px solid lightgray;
  padding: 1.5rem 15px 2rem 0;
}

footer .text-black {
  color: black;
}

footer img {
  margin: 0 0 1.5rem 40px;
}

@media (max-width: 768px) {
  footer {
    padding: 1rem 0 2.5rem 0;
  }
  footer div.row {
    margin-right: 15px;
  }
}

div.follow-us {
  position: fixed;
  padding: 6px 24px 10px 24px;
  bottom: 0px !important;
  top: auto;
  right: 0;
  z-index: 10;
  font-size: x-large;
}

div.follow-us i {
  margin: 0 5px;
}

div.share {
  background-color: #791acd;
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 1000;
  font-size: x-large;
  width: 54px;
}

div.share a {
  color: white !important;
  padding: 9px 15px;
  display: block;
  transition: all 0.5s;
  position: relative;
}

div.share a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
}

div.share a:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

div.share a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid #ffffff80;
  transform: scale(1.2, 1.2);
}

div.share a:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

@media (min-width: 768px) {
  div.share {
    top: 20%;
    bottom: auto;
  }
}

ul.no-list li {
  list-style: none;
}
/*# sourceMappingURL=main.css.map */
