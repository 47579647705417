@import "_types.scss";

.bg-rebecca {
  background-color: $rebecca;
}
.bg-science {
  background-color: $science;
}
.bg-blue {
  background-color: $blue;
}
.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: $black;
}

.navbar {
  .container-fluid {
    .logo {
      img {
        width: 30px;
        height: 30px;
      }
    }
    #navbarMenu {
      flex: 0 0 100%;
    }
  }
}

.page-container {
  background-color: black;
  background-image: url("../img/fondo1.webp");
  background-position: center top;
  background-attachment: fixed;
}

.sdbarCnt {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}
.sidebar {
  background-color: $latte;
}
@media (min-width: 768px) {
  .sidebar {
    order: 0;
    position: sticky;
    z-index: 1000;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .sdbarCnt {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .w-nsm-100 {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.tbar {
  position: fixed;
  top: 20%;
  width: 3pc;
  z-index: 100020;
  background: none;
  animation-name: slideInRight;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
}
.tbar-right {
  right: 0;
  float: right;
  left: auto;
}

.cardBorder {
  border-width: 3px;
  border-radius: 10px;
}

footer {
  border-top: 10px solid lightgray;
  padding: 1.5rem 15px 2rem 0;
  .text-black {
    color: black;
  }
  img {
    margin: 0 0 1.5rem 40px;
  }
}
@media (max-width: 768px) {
  footer {
    padding: 1rem 0 2.5rem 0;
    div.row {
      margin-right: 15px;
    }
  }
}

div.follow-us {
  position: fixed;
  padding: 6px 24px 10px 24px;
  bottom: 0px !important;
  top: auto;
  right: 0;
  z-index: 10;
  font-size: x-large;
  i {
    margin: 0 5px;
  }
}

div.share {
  background-color: $science;
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 1000;
  font-size: x-large;
  width: 54px;
  a {
    color: white !important;
    padding: 9px 15px;
    display: block;

    transition: all 0.5s;
    position: relative;
  }
  a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
  }
  a:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid #ffffff80;
    transform: scale(1.2, 1.2);
  }
  a:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@media (min-width: 768px) {
  div.share {
    top: 20%;
    bottom: auto;
  }
}

ul.no-list {
  li {
    list-style: none;
  }
}
